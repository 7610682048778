

.navbar {
    display: flex;
    justify-content: center;
    width: 80%;
    padding: 1rem;
    background: rgba(248, 249, 250, 0.5);
    box-shadow: 0 0.22rem 0.11rem -0.22rem gray;
    margin-bottom: 3rem;
    max-height: 5.56rem; /* Optional: limit the max height */
    margin-top: 5.56rem; /* Add some space from the top */
    position: fixed;
    top: 0;
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 66.67rem;
    margin: 0 auto;
  }

.nav-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0 1rem; /* Add margin to create space between links */
    
}

.active-link {
    color: red;
}

.nav-link:hover {
    color: #0056b3;
}

/* Styles for screens 1024px wide or less */
@media (max-width: 1024px) {
    
}

/* Styles for screens 768px wide or less */
@media (max-width: 768px) {
    .nav-link {
        font-size: 0.9rem;
        padding: 0.1rem 0.2rem;
        margin: 0 0.5rem; /* Add margin to create space between links */
    }
    .navbar {
        margin-top: 1.3rem
    }
}
/*
    
    background-color: rgba(245, 233, 206, 0.7);
*/

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.11rem;
    width: 65%;
    max-width: 66.67rem;
}

.contact-page-title {
    font-size: 1.11rem;
    font-weight: bold;
    margin-bottom: 1.67rem;
    color: #0056b3;
}

.contact-form-overlay {
    
}

.contact-page-content {
    width: 100%;
    max-width: 44.44rem;
    margin-top: 1.11rem;
}

.contact-page-form {
    background-color: rgba(204, 221, 240, 0.5);
}

@media (max-width: 768px) {
    .contact-page {
        width: 100%;
        padding: 0px;
    }
}
/*
background-color: rgba(208, 222, 242, 0.7);
.contact-page-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
    background-color: rgba(208, 222, 242, 0.7);
    box-shadow: 0 4px 2px -2px gray;
    margin-bottom: 20px;
    position: sticky; 
    top: 0;
    z-index: 1000;
}

.contact-page-banner-tab {
    flex: 1; 
    padding: 1em 0;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
    border-bottom: 3px solid transparent;
    transition: border-bottom 0.3s;
}

.contact-page-banner-tab.active {
    border-bottom: 3px solid #007bff;
    color: #0056b3;
}

.contact-page-banner-tab:hover {
    color: #0056b3;
}
*/


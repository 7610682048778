.spinner {
    border: 0.22rem solid rgba(0, 0, 0, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 0.89rem;
    height: 0.89rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .submit-button {
    padding: 0.56rem 1.11rem;
    border: none;
    border-radius: 0.28rem;
    background-color: rgba(0, 86, 179, 0.5);
    color: white;
    cursor: pointer;
    font-size: 1rem;
}
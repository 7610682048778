
.contact-form {
    display: flex;
    align-items: center;
    border-radius: 0.83rem; /* Optional: Rounded corners for aesthetics */
    width: 100%;
    margin: auto;
}

.contact-form-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.56rem;
    align-items: center;
    border-radius: 0.56rem; /* Optional: Rounded corners for aesthetics */
    box-shadow: 0 0.22rem 0.44rem rgba(0, 0, 0, 0.1);
    width: 80%;
    margin: auto;
    padding: 2.22rem 0px 2.22rem 0px;
}

.contact-form label {
    text-align: left;
    margin-right: 0.56rem;
    color: #0056b3;
    font-weight: bold;
    font-size: 1rem;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.56rem;
    margin-top: 1.11rem;
    margin-bottom: 1.11rem;
    border: 0.11rem solid #ccc;
    border-radius: 0.28rem;
    box-sizing: border-box;
    line-height: 1.4;
}

.enquiry-options select {
    padding: 1.11rem;
    border: 0.11rem solid #ccc;
    border-radius: 0.28rem;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    text-align: center;
}

.send-button {
    grid-column: span 2; /* Make the button span across both columns */
    padding: 0.56rem 1.11rem;
    background-color: rgba(0, 86, 179, 0.5);
    border: none;
    border-radius: 0.28rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    justify-self: center; /* Center the button horizontally */
}

.send-button:hover {
    background-color: rgba(0, 86, 179, 0.9);
}

.contact-form-recaptcha {
    grid-column: span 2;
    margin: 1.67rem, 0px, 1.67rem, 0px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
}

.contact-form-status-message-container {
    grid-column: span 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form-status-message {
    margin: 1.39rem 0px 0px 0px;
    padding: 0.56rem;
    border-radius: 0.44rem;
    text-align: center;
    max-width: 27.78rem;
    box-sizing: border-box;
}

.contact-form-status-message.success {
    background-color: rgba(161, 187, 240, 0.65);
    color: #0056b3;
    border: 1px solid rgba(0, 86, 179, 0.8);
    margin-bottom: 1.39rem;
    font-weight: bold;
}

.contact-form-status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 0.11rem solid #721c24;
    margin-bottom: 1.39rem;
}

.contact-form-ok-button {
    padding: 0.44rem;
    margin-left: 1.11rem;
    margin-top: 0.56rem;
    margin-bottom: 0.56rem;
    border-radius: 0.33rem;
    font-size: 1rem;
}

.contact-form-ok-button.success {
    background-color: rgba(204, 221, 240, 1);
    border: 0.11rem solid rgba(0, 86, 179, 0.8);
}

.contact-form-ok-button.error {
    background-color: rgba(0, 86, 179, 0.5);
    border: 0.11rem solid #721c24;
    color: white;
}

@media (max-width: 768px) {
    .contact-form {
        width: 95%;
    }
    .contact-form-container {
        width: 93%
    }
}
/*
background-color: #d4edda;
color: #155724;
background: rgba(255, 255, 255, 0.8);

.contact-form-status-message.success {
    background-color: rgba(0, 86, 179, 0.65);
    color: white;
    border: 1px solid rgba(0, 86, 179, 0.8);
    margin-bottom: 25px;
}
        border: 1px solid #f5c6cb;
*/

.navigate-arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: rgba(67, 98, 222, 0.8);
    font-size: 2em;
    padding: 1rem;
    cursor: pointer;
    z-index: 2;
}

.navigate-arrow.back {
    margin-left: 2rem;
}

.navigate-arrow.next {
    margin-right: 2rem;
}

.navigate-arrow:hover {
    color: blue;
}
/*
background-color: rgba(219, 182, 216, 0.8);
transform: translateY(-50%);
*/


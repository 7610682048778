.data-privacy-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.11rem;
    width: 100%;
    box-sizing: border-box;
}

.data-privacy-text-content {
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center;
    margin: 0 1.11rem;
    padding: 0 0.44rem;
    line-height: 1.4;
    width: 50%;
    
    box-sizing: border-box;
}

.data-privacy-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

.data-privacy-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.data-privacy-form h2 {
    margin-bottom: 2.22rem;
  }
  
.data-privacy-form label {
    align-self: centert;
    margin-bottom: 0.28rem;
    font-weight: bold;
}
  
.data-privacy-form input,
.data-privacy-form textarea {
    width: 100%;
    padding: 0.56rem;
    margin-top: 1.11rem;
    margin-bottom: 1.11rem;
    border: 0.056rem solid #ccc;
    border-radius: 0.44rem;
    box-sizing: border-box;
}

.data-privacy-form button {
    padding: 1.11rem;
    border: none;
    border-radius: 0.28rem;
    background-color: rgba(0, 86, 179, 0.5);
    color: white;
    cursor: pointer;
    font-size: 0.89rem;
}

.data-privacy-form button:hover {
    background-color: rgba(0, 86, 179, 0.9);
}

.request-options {
    margin: 0.83rem 0 0 0;
    width: 100%;
}

.request-options select {
    padding: 0.56rem;
    border: 0.056rem solid #ccc;
    border-radius: 0.28rem;
    box-sizing: border-box;
    width: 100%;
}

.request-options option {
    
}

.additional-info {
    margin: 0.83rem 0 0.56rem 0px;
    width: 100%;
}

.recaptcha {
    margin: 1.67rem, 0px, 1.67rem, 1.11rem;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
}

.data-privacy-status-message {
    margin: 1.39rem 0px 0px 0px;
    padding: 0.56rem;
    border-radius: 0.44rem;
    text-align: center;
    width: 100%;
    max-width: 27.78rem;
    box-sizing: border-box;
    font-size: 1rem;
}

.no-status{
    margin-bottom: 0px;
}

.data-privacy-status-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 0.056rem solid #c3e6cb;
    margin-bottom: 1.39rem;
}

.data-privacy-status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 0.056rem solid #f5c6cb;
    margin-bottom: 1.39rem;
}

.data-privacy-status-message button {
    padding: 0.44rem;
    margin-left: 1.11rem;
    margin-top: 0.56rem;
    margin-bottom: 0.56rem;
    border-radius: 0.33rem;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .data-privacy-form h2 {
        font-size: 1.5rem;
        margin-bottom: 1.11rem;
      }
    .data-privacy-text-content {
        width: 85%;
    }
    .status-message {
        max-width: 22.22rem;
    }
}

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.11rem; /* 20 / 18 */
}

.home-page-title {
    font-size: 1em;
}

.home-page-logo {
    width: 6.56rem;
    height: 6.56rem;
    margin: 0.5rem 0 1.11rem 0;
}

.home-page-description {
    font-size: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 1.11rem;
    line-height: 1.6;
    font-weight: bold;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.home-page-intro {
    display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 66.67rem; /* 1200/18 */
  margin: 0 auto; /* Center the content */
  padding: 0 2rem;
  box-sizing: border-box;
}

.home-page-text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 1.11rem;
    padding: 0 0.44rem;
    flex: 1;
    transition: width 0.3s ease;
}

.home-page-screenshots {
    position: relative; /* Enable positioning for children */
    display: flex;
    justify-content: center;
    width: 25%;
}

.home-page-screenshot {
    width: 80%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 0.83rem;
    opacity: 0.6;
}
/*
.home-page-screenshot.home-page-screenshot-left {
    transform: rotate(-5deg);
}

.home-page-screenshot.home-page-screenshot-right {
    transform: rotate(5deg);
}*/

.home-page-screenshot.home-page-screenshot-overlap {
    position: absolute;
    top: 10%;
    left: 50%; 
    width: 80%;
    height: auto;
    transition: transform 0.3s ease;
  }

.home-page-screenshot:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.home-page-app-descriptions {
    width: 100%;
    max-width: 66.67rem;
    margin: 5.5rem 0px 0px 0px;
}

.home-page-app-descriptions-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.home-page-description-screenshot {
    margin-top: 1.11rem;
    margin-bottom: 1.8rem;
    max-width: 55.56rem;
    border-radius: 0.83rem;
    height: 22.22rem;
    object-fit: cover; /* Ensure the image covers the container */
    object-position: center; /* Center the image */
}

.home-page-app-descriptions-section {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    width: 100%;
    max-width: 55.56rem;
    box-sizing: border-box;
    
    z-index: 1
}

.navigate-icon {
    width: 5%;
    margin-right: 0.8rem;
}

.home-page-app-description-text {
    width: 50%;
    line-height: 1.8;
    margin-right: 6%;
    font-size: 1rem;
    font-weight: bold;
}

.home-page-app-description-container {
    background-color: rgba(204, 221, 240, 0.5);
    padding-top: 1.5rem;
    padding-bottom: 3rem;
}

.slick-slide {
    background-color: rgba(204, 221, 240, 0.5);
    width: 100%;
    display: flex !important;
    align-items: center; /* Center the slides vertically */
    justify-content: center;
    flex-direction: row;
    z-index: -1; /* Place it behind the content */
    border-radius: 1.11rem; /* Match the border radius if needed */
    padding: 1.11rem 0px 1.11rem 0px;
    box-shadow: 0 0.22rem 0.44rem rgba(0, 0, 0, 0.1);
}
/*
background: rgba(255, 255, 255, 0.6);
*/

.slick-slider {
    overflow: hidden; /* To avoid overflow issues */
}

.slick-list {
    margin: 0 -1.11rem; /* Adjust margin to fit the container */
}

.slick-track {
    
    
}

.slick-next::before, .slick-prev::before {
    content: none;
}

/* Styles for screens 1024px wide or less */
@media (max-width: 1024px) {
    
}

/* Styles for screens 768px wide or less */
@media (max-width: 768px) {
    .home-page-screenshot.home-page-screenshot-left,
    .home-page-screenshot.home-page-screenshot-overlap {
        display: none;
    }
    .home-page-title {
        font-size: 0.8rem;
    }
    .home-page-logo {
        width: 5.56rem;
        height: 5.56rem;
        margin: 0.5rem 0 1.11rem 0;
    }
    .home-page-text-content {
        width: 100%;
    }
    .home-page-description {
        width: 100%;
    }
    .home-page-app-descriptions {
        max-width: 66.67rem;
        margin: 2rem 0px 0px 0px;
    }
    .home-page-app-description-text { 
        line-height: 1.45;
    }
    .home-page-app-descriptions-section {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
        width: 100%;
        max-width: 35rem;
        box-sizing: border-box;
        z-index: 1
    }
    .slick-slide {
        
    }
}

/*
background-color: red;
}
background-color: rgba(0, 0, 0, 0);
.home-page-description-image-container.half {
    width: 40%; 
    height: 50%; 
    overflow: hidden; 
    position: relative; 
}

.home-page-description-image-container.full {
    width: 30%; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    overflow: hidden; 
    position: relative; 
    
}
*/


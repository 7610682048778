html {
  font-size: 18px; /* 1rem = 18px */
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure the parent takes at least full viewport height */
}

.background-container {
  background-image: url('./images/CAN-Chat-AI-gen-01.jpg'); /* Path to the image */
    background-size: cover; /* Cover the entire element */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating */
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 70%; /* Ensure the content area takes up 70% of the space */
  box-sizing: border-box;
  padding-top: 11.11rem;
  width: 100%;
}

.page {
  width: 100%;
  text-align: center;
}

.page-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  margin: 0 auto; /* Center the content */
  padding: 0 2.22rem;
  box-sizing: border-box;

}

@media (max-width: 768px) {
  .app-content {
    padding-top: 5.45rem;
  }

}
/*
max-width: 66.67rem; /* Set a maximum width to prevent stretching 
*/

.private-policy-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.11rem;
    min-height: 0;
    width: 100%;
}

.private-policy-text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 1.11rem;
    padding: 0 0.44rem;
    line-height: 1.4;
    width: 80%;

}

.private-policy-text-content-overlay {
    padding: 1.11rem 4.44rem 1.11rem 4.44rem;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for readability */
    border-radius: 1.11rem; /* Optional: Rounded corners for aesthetics */
    box-shadow: 0 0.22rem 0.44rem rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling */

}

@media (max-width: 768px) {
    .private-policy-text-content-overlay {
        padding: 1.11rem 2rem;
    }
    .private-policy-text-content-overlay h1 {
        font-size: 1.5rem;
    }
    .private-policy-text-content {
        padding: 0 0.22rem;
        width: 90%;
    }
}